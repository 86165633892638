import { useState } from "react"

import { ParadiseHomeDeleteDialog } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeDeleteDialog"
import { ParadiseHomeRestoreDialog } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeRestoreDialog"
import { ParadiseHomeTransferDialog } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeTransferDialog"
import { ActionsWrapper } from "src/components/Paradise/sharedStyles"
import { RequireRoles } from "src/components/RestrictedRoles/RequireRoles"
import { IParadiseHome } from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { UserRoles } from "src/data/user/user"
import { MButton } from "src/ui/Button/MButton"

export function ParadiseHomeActions({ home }: { home: IParadiseHome }) {
  const [showTransferDialog, setShowTransferDialog] = useState(false)
  const [showRestoreDialog, setShowRestoreDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  return (
    <RequireRoles roles={[UserRoles.SUPERADMIN]}>
      <ActionsWrapper>
        {home.deleted && (
          <MButton
            variant="secondary"
            size="small"
            onClick={() => setShowRestoreDialog(true)}
          >
            Restore
          </MButton>
        )}
        {!home.deleted && (
          <MButton
            variant="primary"
            color="destructive"
            size="small"
            onClick={() => setShowDeleteDialog(true)}
          >
            Delete Unit
          </MButton>
        )}
        <MButton
          variant="subtle"
          size="small"
          onClick={() => setShowTransferDialog(true)}
        >
          Transfer
        </MButton>
      </ActionsWrapper>
      <ParadiseHomeTransferDialog
        open={showTransferDialog}
        homeOwnerId={home.owner.id}
        homeName={home.name}
        homeId={home.id}
        onClose={() => setShowTransferDialog(false)}
      />
      <ParadiseHomeRestoreDialog
        open={showRestoreDialog}
        homeName={home.name}
        homeId={home.id}
        onClose={() => setShowRestoreDialog(false)}
      />
      <ParadiseHomeDeleteDialog
        open={showDeleteDialog}
        homeName={home.name}
        homeId={home.id}
        orgId={home.owner.id}
        onClose={() => setShowDeleteDialog(false)}
      />
    </RequireRoles>
  )
}
