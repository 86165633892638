import { useDeleteHome } from "src/data/organizations/queries/homeQueries"
import { useParadiseHomesCache } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueryCache"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"

export function ParadiseHomeDeleteDialog({
  open,
  onClose,
  homeName,
  homeId,
  orgId,
}: {
  open: boolean
  onClose: () => void
  homeName: string
  homeId: string
  orgId: string
}) {
  const putDeleteHome = useDeleteHome()
  const paradiseHomecache = useParadiseHomesCache()

  function handleDelete() {
    putDeleteHome.mutate(
      { homeId, orgId },
      {
        onSuccess: () => {
          paradiseHomecache.invalidateParadiseHome(homeId)
          onClose()
        },
      }
    )
  }

  return (
    <MDialog
      open={open}
      title={`Delete ${homeName}`}
      onClose={() => {
        onClose()
      }}
      confirmLabel={"Delete unit"}
      confirmButtonProps={{ color: "destructive" }}
      loading={putDeleteHome.isLoading}
      onConfirm={handleDelete}
      error={
        putDeleteHome.isError &&
        (putDeleteHome.error?.response?.data ?? putDeleteHome.error.message)
      }
    >
      <div>
        <MText variant="body">
          All the data for this unit and its devices will be permanently
          deleted. This action cannot be reversed.
        </MText>
      </div>
    </MDialog>
  )
}
